/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import ImgWithFallback from 'app/components/webpimage';
// import VideoComponent from 'app/components/VideoComponent';
import InfoIcon from '@mui/icons-material/Info';

export default function HomepageStepsImage(Info) {
  // console.log('FEATURE SPOTLIGHT', Info);
  const navigate = useNavigate();
  return (
    // <Mobile />
    Info.align !== 'left' && RENDER === 'CLIENT' ? (
      <Grid
        container
        // xs={12}
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '10% 0px',
          margin: 'auto',
        }}>
        <Hidden mdUp>
          <Grid item justifyContent="center" xs={12} md={6}>
            {/* <VideoComponent
              src={Info.src}
              style={{maxWidth: '90%', objectFit: 'contain', padding: '5%'}}
            /> */}
            <picture style={{padding: '6% 6%'}}>
              <source
                srcSet={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.webp`}
                type="image/webp"
                style={{
                  // width: '95%',
                  // minWidth: '100%',
                  // minHeight: '100%',
                  // maxWidth: '600px',
                  // height: 'auto',
                  // display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                width="1859"
                height="1483"
              />
              <img
                src={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.jpg`}
                alt="home-header"
                width="1859"
                height="1483"
                style={{
                  width: '95%',
                  // minWidth: '100%',
                  // minHeight: '100%',
                  // maxWidth: '600px',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                loading="lazy"
              />
            </picture>
          </Grid>
        </Hidden>
        {/* <Hidden mdDown> */}
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={10}
            style={{
              textAlign: 'left',
              margin: 'auto',
              // padding: '0px 20px',
              minWidth: '80%',
              maxWidth: '81%',
            }}>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              paragraph
              style={{fontWeight: '600'}}>
              {`Step ${Info.step}`}
            </Typography>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              paragraph
              style={{fontWeight: '600'}}>
              {Info.title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                textAlign: 'left',
              }}>
              {Info.text}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                paddingTop: '14px',
                textAlign: 'left',
                fontWeight: '600',
              }}>
              <span>
                <InfoIcon style={{marginBottom: '-6px'}} />
                {` ${Info.question}`}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                textAlign: 'left',
              }}>
              {Info.answer}
            </Typography>
          </Grid>
        </Grid>
        {/* </Hidden> */}
        <Hidden mdDown>
          <Grid item justifyContent="center" xs={12} md={6}>
            {/* <VideoComponent
              src={Info.src}
              style={{maxWidth: '90%', objectFit: 'contain', padding: '5%'}}
            /> */}
            <picture style={{padding: '6% 6%'}}>
              <source
                srcSet={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.webp`}
                type="image/webp"
                style={{
                  // width: '95%',
                  // minWidth: '100%',
                  // minHeight: '100%',
                  // maxWidth: '600px',
                  // height: 'auto',
                  // display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                width="1859"
                height="1483"
              />
              <img
                src={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.jpg`}
                alt="home-header"
                width="1859"
                height="1483"
                style={{
                  width: '95%',
                  // minWidth: '100%',
                  // minHeight: '100%',
                  // maxWidth: '600px',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                loading="lazy"
              />
            </picture>
          </Grid>
        </Hidden>
      </Grid>
    ) : (
      <Grid
        container
        // maxWidth="lg"
        alignItems="center"
        style={{
          // backgroundColor: '#ffffff',
          minHeight: '500px',
          padding: '50px 0px 0px',
          margin: 'auto',
        }}>
        <Grid item justifyContent="center" xs={12} md={6}>
          {/* <VideoComponent
            src={Info.src}
            style={{maxWidth: '90%', objectFit: 'contain', padding: '5%'}}
          /> */}
          <picture style={{padding: '6% 6%'}}>
            <source
              srcSet={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.webp`}
              type="image/webp"
              style={{
                // width: '95%',
                // minWidth: '100%',
                // minHeight: '100%',
                // maxWidth: '600px',
                // height: 'auto',
                // display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              width="1859"
              height="1483"
            />
            <img
              src={`https://storage.googleapis.com/find-me-sales-bucket${Info.src}.jpg`}
              alt="home-header"
              width="1859"
              height="1483"
              style={{
                width: '95%',
                // minWidth: '100%',
                // minHeight: '100%',
                // maxWidth: '600px',
                height: 'auto',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              loading="lazy"
            />
          </picture>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            item
            xs={10}
            style={{
              textAlign: 'left',
              margin: 'auto',
              // padding: '0px 20px',
              minWidth: '80%',
              maxWidth: '81%',
            }}>
            <Typography
              variant="h6"
              align="left"
              color="primary"
              paragraph
              style={{fontWeight: '600'}}>
              {`Step ${Info.step}`}
            </Typography>
            <Typography
              variant="h5"
              align="left"
              color="primary"
              paragraph
              style={{fontWeight: '600'}}>
              {Info.title}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                textAlign: 'left',
              }}>
              {Info.text}
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                paddingTop: '14px',
                textAlign: 'left',
                fontWeight: '600',
              }}>
              <span>
                <InfoIcon style={{marginBottom: '-6px'}} />
                {` ${Info.question}`}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              paragraph
              style={{
                textAlign: 'left',
              }}>
              {Info.answer}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  );
}
